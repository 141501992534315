/*====== All Custom Files Include Here ======*/
@import "./custom/common";
@import "./custom/header";
@import "./custom/sidebar";
@import "./custom/horizontal-menu";
@import "./custom/agency-layout";
@import "./custom/main";
@import "./custom/dashboard";
@import "./custom/ul-element";
@import "./custom/advance-ui-element";
@import "./custom/forms";
@import "./custom/tables";
@import "./custom/editor";
@import "./custom/drag-drop";
@import "./custom/pages";
@import "./custom/user";
@import "./custom/ecommerce";
@import "./custom/session";
@import "./custom/effect";
@import "./custom/widgets";
@import "./custom/extensions";


// .MuiSvgIcon-root{
//   display: none !important;
// }
.humburger .MuiSvgIcon-root{
  display: block !important;
}
.MuiSelect-icon{
display: block !important;
font-size: 2.5rem !important;
}

.MuiButton-label{
  font-weight: 750;

}
.MUIDataTableToolbar{
  display: none !important;
}
.MuiPaper-root-MuiDialog-paper{
  max-width: 69rem !important;
}

.img-box img{
  width: 160px;
  height: 160px;
  border-radius: 10px;
 }
 .title h2{
   font-size:25px;
   font-weight:bold;
   
 }
 .title span{
   font-size:14px;
 }

//    .MuiToolbar-regular {
//     min-height: 64px;
//     display: none;
// }

.MuiTableCell-footer{
display: none !important;
}

.MuiCollapse-entered{
display: block !important;
}
.MuiCollapse-hidden{
display: block !important;
}

a{
  text-decoration: none !important;
}


